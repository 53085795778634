
jQuery(function($, undefined) {
    $(document).on('ajaxLoaded', function() {
        bind($(this));
    });
    bind(document);
    function bind(root) {
        $('div[data-balance-container]', root).each(function() {
            var $container = $(this),
                $currencySelect = $container.find($container.data('balanceContainer')),
                $items = $container.find('[data-balance]'),
                $selectedItems = $items.filter(function() {
                    var $elem = $(this);
                    return $elem.is('div') || $elem.is(':checked');
                })
                ;
            $container.on('click change', '[data-balance]', function() {
                selected($(this));
            });
            if ($selectedItems.length > 0) {
                selected($selectedItems);
            }

            function selected($selected) {
                var balance = $selected.data('balance');
                if ($.type(balance) === 'object') {
                    $currencySelect.children().each(function() {
                        var $this = $(this);
                        var amount = balance[$this.val()];
                        if (amount !== undefined) {
                            $this.text($this.val() + ' ' + amount);
                        } else {
                            $this.text($this.val());
                        }
                    });
                }
            }
        });
    }
});