jQuery(function($){
    var $languageDropdown =  $('.language-selection-wrapper select');
    var $languageSelected = $('.language-selection-wrapper select option[selected]');

    $languageDropdown.msDropdown({
        'mainCSS': 'dd'
    });

    $languageDropdown.data('dd').setIndexByValue($languageSelected.val());
    $languageDropdown.on('change', function(){
        window.location.href = $(this).val();
    });
});