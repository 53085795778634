(function($) {

    $.fn.evpError = function(message) {
        if (message) {
            alert(message);
            return;
        }
        var container = $('.ajax-container');
        var errorMessage = container.length > 0 && container.data('error-text') !== undefined
            ? container.data('error-text')
            : 'An unknown error occurred. Please contact Paysera for assistance.'
        ;
        alert(errorMessage);
    };

}(jQuery));
